import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

import Columns from "../components/columns.js";
import Divider from "../components/divider.js";
import Layout from "../components/layout.js";
import Post from "../components/post.js";

import {
  another1,
  another2,
  another3,
  another4,
  another5,
  another6,
} from "../images/comedy/another";
import { chickago1, chickago2 } from "../images/comedy/chickago";
import { fad22_1, fad22_2 } from "../images/comedy/fad22";
import { fad23_1 } from "../images/comedy/fad23";
import { phys1, phys2, phys3, phys5 } from "../images/comedy/phys";
import { powerhour1 } from "../images/comedy/powerhour";
import { sf1, sf2, sf3 } from "../images/comedy/springfling";
import { union1, union2, union3, union4 } from "../images/comedy/union";
import chickenCooking from "../images/gifs/chicken.gif";

const ComedyPage = ({ chickenBigEnabled = false }) => {
  console.log(phys1);
  console.log(union1);
  return (
    <Layout
      title={!chickenBigEnabled && "lars montanaro | comedy"}
      image={
        <StaticImage
          alt="Chicken Big performing at Carroll Hall"
          src="../images/header_comedy.png"
        />
      }
      showNav={!chickenBigEnabled}
    >
      <Columns>
        <div className="max-w-prose">
          <h2 className="mb-4">ABOUT</h2>
          <div className="text-md">
            <p>
              Chicken Big is a Brooklyn-based comedy trio (Tej Khanna, Ibhan
              Kulkarni, and Lars Montanaro) that produce and perform improv,
              clown, and experimental comedy experiences. They are a house team
              at the Brooklyn Comedy Collective, and have been featured
              performers & teachers at Asylum NYC, The PIT, iO Chicago, The
              Annoyance, Logan Square Improv, New York University, and Oberlin
              College. They have been featured in PhysFest NYC ('24), New York
              Comedy Fest ('23), and headlined the Fun and Dumb Improv Festival
              ('22, '23, '24') and the Union Improv Festival ('22).
            </p>
            &nbsp;
            <p>
              See them at the Brooklyn Comedy Collective the third Friday of
              every month @ 7pm and Tuesday Night Home Teams @ 7pm!
            </p>
            &nbsp;
            <p>
              Hit them up for booking at{" "}
              <a
                className="underline hover:text-pink-400"
                href="mailto:chickenbigimprov@gmail.com"
              >
                chickenbigimprov@gmail.com
              </a>
            </p>
          </div>
          <h2 className="mt-8 fontweight-bold">NEWS</h2>
          <Post
            title={<p>Chicken Big: Gets Physical (FEASTMACHINE)</p>}
            images={[
              { original: phys1 },
              { original: phys2 },
              { original: phys3 },
              { original: phys5 },
            ]}
          >
            <p className="text-sm">
              Chicken Big's original hour of clown at{" "}
              <a
                className="underline hover:text-pink-400"
                target="_blank"
                href="https://www.physfestnyc.org/"
              >
                PhysFest NYC
              </a>
              !
            </p>
          </Post>
          <Divider />
          <Post
            title={<p>Chicken Big: Chicago Style</p>}
            images={[{ original: chickago1 }, { original: chickago2 }]}
          >
            <p className="text-sm">
              Chicken Big tours Chicago, with performances at Annoyance Theater,
              iO Theater, Logan Square Improv, Lincoln Lodge, and Laugh Factory
              Chicago.
            </p>
          </Post>
          <Divider />
          <Post
            title={
              <p>
                <a
                  className="underline hover:text-pink-400"
                  target="_blank"
                  href="https://www.brooklyncc.com/fad"
                >
                  Fun & Dumb Improv Festival '23
                </a>
                , Brooklyn
              </p>
            }
            images={[{ original: fad23_1 }]}
          >
            <p className="text-sm">
              Chicken Big headlines Fun & Dumb (again!). Produced by the
              Brooklyn Comedy Collective.
            </p>
          </Post>
          <Divider />
          <Post
            title={<p>Chicken Big Power Hour</p>}
            images={[{ original: powerhour1 }]}
          >
            <p className="text-sm">
              First Friday of every month at the Brooklyn Comedy Collective.
            </p>
          </Post>
          <Divider />
          <Post
            title={"Spring Fling: An Evening of Comedy"}
            images={[{ original: sf1 }, { original: sf2 }, { original: sf3 }]}
          >
            <p className="text-sm">
              Carrol Hall Gardens in Brooklyn. Featuring Emote Control, Not Like
              Other Girls. Photos by Michael Gebhardt.
            </p>
          </Post>
          <Divider />
          <Post
            title={"Another Evening with Chicken Big"}
            images={[
              { original: another1 },
              { original: another2 },
              { original: another3 },
              { original: another4 },
              { original: another5 },
              { original: another6 },
            ]}
          >
            <p className="text-sm">
              Carrol Hall Gardens in Brooklyn. Featuring Neel Ghosh, Ladies Who
              Ranch, and Itchy Scratchy. Photos by Danny Damian
            </p>
          </Post>
          <Divider />
          <Post
            title={
              <span>
                <a
                  className="underline hover:text-pink-400"
                  target="_blank"
                  href="https://www.unioncomedy.com/festival"
                >
                  Union Improv Festival
                </a>
                , Boston
              </span>
            }
            images={[
              { original: union1 },
              { original: union2 },
              { original: union3 },
              { original: union4 },
            ]}
          >
            <p className="text-sm">
              Produced by Union Comedy, at the Crystal Ballroom in Davis Square.
              Photos by Will Gianetta.
            </p>
          </Post>
          <Divider />
          <Post
            title={
              <p>
                <a
                  className="underline hover:text-pink-400"
                  target="_blank"
                  href="https://www.brooklyncc.com/show-schedule/2022/6/20/fun-and-dumb"
                >
                  Fun & Dumb Improv Festival, '22
                </a>
                , Brooklyn
              </p>
            }
            images={[{ original: fad22_1 }, { original: fad22_2 }]}
          >
            <p className="text-sm">
              Produced by the Brooklyn Comedy Collective.
            </p>
          </Post>
          <Divider />
          <Post title={<p>Teaching & Performing at Oberlin College, Ohio</p>}>
            <p className="text-sm">
              Performed at The Cat in the Cream. Taught workshops for college
              students and local performers.
            </p>
          </Post>
        </div>
        <div className="sm:ml-4">
          <div className="sm:sticky sm:top-14 pb-8">
            <h2>FOLLOW</h2>
            <iframe
              height="360"
              src="https://www.instagram.com/chickenbigcomedy/embed"
              style={{ margin: "auto", maxWidth: "350" }}
            ></iframe>
            <div className="hidden sm:block">
              <h2 className="mt-8 sm:text-center">merch is cooking...</h2>
              <img className="h-[80px] sm:mx-auto" src={chickenCooking} />
            </div>
          </div>
        </div>
      </Columns>
    </Layout>
  );
};

export const Head = () => <title>Lars Montanaro</title>;

export default ComedyPage;
